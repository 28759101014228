var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("editor", {
    attrs: { "api-key": _vm.apikey, init: _vm.config },
    model: {
      value: _vm.inputVal,
      callback: function ($$v) {
        _vm.inputVal = $$v
      },
      expression: "inputVal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }